import Vue from "vue"
import VueRouter from "vue-router"
import Cookies from 'js-cookie';

Vue.use(VueRouter)


const routes = [{
    path: "/",
    redirect: '/login'
  },
  {
    path: "/login",
    component: () => import('./views/login'),
    meta: {
      auth: false
    }
  },
  {
    path: "/home",
    component: () => import('./views/home'),
    meta: {
      auth: true
    }
  }, {
    path: "/test",
    component: () => import('./views/test'),
    meta: {
      auth: false
    }
  }
]

const router = new VueRouter({
  // mode: '',//history
  routes
})

// 复制到这
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

/** 验证用户是否登录 **/
router.beforeEach((to, from, next) => {
  const token = Cookies.get('token') || Cookies.get('Authorization');
  const userId = Cookies.get('userId');
  if (token && userId) {
    const userInfo = {
      name: Cookies.get('name'),
      userId,
      token,
    };
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    sessionStorage.setItem("token", token);
    localStorage.setItem("token", token);
    localStorage.setItem('from', 1);
  }
  let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  if (to.meta.auth) {
    if (userInfo) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})


export default router;