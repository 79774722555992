import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    timer: null,
    chinaMapData: null,
    indexData: {
      studyTotalHistory: {
        studyTotal: 0
      },
    },
    motheData: {},
    dayhData: {},
    dayhData2: [],
    HospitalCode: [], // 所有的医院的CODE
    readData: {
      total: 0
    },
    rollData: [],
    currentHospital: {},
    mapLevel: 1,
    handleBack: 1,
    activeCode: '',
    flag: true // 今日、汇总切换
  },
  getters: {
    token: state => state.token,
    chinaMapData: state => state.chinaMapData,
    indexData: state => state.indexData,
    motheData: state => state.motheData,
    dayhData: state => state.dayhData,
    HospitalCode: state => state.HospitalCode,
    readData: state => state.readData,
    rollData: state => state.rollData,
    currentHospital: state => state.readData,
    flag: state => state.flag,

  },
  mutations: {
    updateToken(state, tokenValue) {
      state.token = tokenValue;
    },
    //医院权限
    updateChinaMap(state, value) {
      state.chinaMapData = value;
    },
    //返回的主页面数据
    indexTjDate(state, indexValue) {
      state.indexData = indexValue;
    },
    //返回的月检查量趋势
    motheTjDate(state, indexVa) {
      state.motheData = indexVa;
    },
    //返回的今日医院排名
    dayhospDate(state, indexV) {
      state.dayhData = indexV;
    },
    //返回的今日医院排名
    dayhospDate2(state, indexV) {
      state.dayhData2 = indexV;
    },
    // 医院code
    updateHosCode(state, indexV) {
      state.HospitalCode = indexV;
    },
    // 调阅次数
    readnumDate(state, indexV) {
      state.readData = indexV;
    },
    // 最新检查数据 滚动信息
    rollnumDate(state, indexV) {
      state.rollData = indexV;
    },
    // 设置当前医院
    setCurrentHospital(state, indexV) {
      state.currentHospital = indexV;
    },
    // 设置地图层级
    setHandleBack(state, indexV) {
      state.handleBack = indexV;
    },
    // 当前选中的医院的code
    setActiveCode(state, indexV) {
      state.activeCode = indexV;
    },
    // 设置地图等级
    setMapLevel(state, indexV) {
      state.mapLevel = indexV;
    },
    // 设置今日、汇总切换
    setFlag(state, indexV) {
      console.log('indexV', indexV)
      state.flag = indexV;
    },

  },
  actions: {
    loadHosData(context, params) {
      Vue.prototype.axios.get("/stat/queryHospitalRankForStat.service", {
        params: params
      }).then(result => {
        if (result && result['success']) {
          context.commit('dayhospDate', result.data)
        }
      })
    },
    loadHosData2(context, params) {
      Vue.prototype.axios.get("/stat/queryHospitalRankForStat.service", {
        params: params
      }).then(result => {
        if (result && result['success']) {
          context.commit('dayhospDate2', result.data)
        }
      })
    },
    loadIndexTjDate(context, code) {
      Vue.prototype.axios.get("/stat/queryStatMain.service", {
        params: {
          currentDate: Vue.prototype.$moment(new Date()).format("YYYYMMDD"), //"20200215",
          hospitalCodeList: code
        }
      }).then(result => {
        if (result && result['success']) {
          context.commit('indexTjDate', result.data)
        }
      })
    },
    loadMotheTjDate(context, code) {
      Vue.prototype.axios.get("/stat/queryStudyTotalByMonth.service", {
        params: {
          startDate: Vue.prototype.$moment(new Date(new Date().getTime() - 364 * 24 * 60 * 60 * 1000) - 1).format("YYYYMMDD"),
          endDate: Vue.prototype.$moment(new Date()).format("YYYYMMDD"),
          hospitalCodeList: code
        }
      }).then(result => {
        if (result && result['success']) {
          context.commit('motheTjDate', result.data)
        }
      })
    },
    loadRollForm(context, code) {
      Vue.prototype.axios.get("/stat/queryStudyIndexTop.service", {
        params: {
          page: {
            current: 1, // 当前页pageNum
            length: 10 // 每页显示条数pageSize
          },
          hospitalCodeList: code
        }
      }).then(result => {
        if (result && result['success']) {
          context.commit('rollnumDate', result.data)
        }
      })
    },
    loadReadForm(context, code) {
      Vue.prototype.axios.get("/stat/queryStudyPvTotal.service", {
        params: {
          startDate: "",
          endDate: "",
          hospitalCodeList: code
        }
      }).then(result => {
        if (result && result['success']) {
          context.commit('readnumDate', result.data)
        }
      })
    }

  }
});