import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import store from "./store";

import router from './router'


// 配置一些请求参数
let _axios = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/foo' : '/',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        /* 'X-Requested-With：XMLHttpRequest',
      'Content-Type': 'application/json;charset=UTF-8;application/x-www-form-urlencoded;multipart/form-data' */
    },
    timeout: 1000000,
    changeOrigin: true,
})
_axios.interceptors.request.use((config) => {

    if (sessionStorage.getItem('userInfo')) {
        config.headers.token = JSON.parse(sessionStorage.getItem('userInfo')).token
    }
    /* 禁止接口缓存 */
    if (config.method === 'get') {
        config.params = {
            ...config.params, t: new Date().getTime()
        }
    }
    return config;

}, function (error) {
    console.log(333333333)
    Vue.prototype.$message({
        message: error.message || '系统出错，请联系管理员',
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(error);
});

_axios.interceptors.response.use((response) => {
    if (response['data']['code'] == '103001001') { // errorMsg
        //console.log(22222)
        setTimeout(() => {
            clearInterval(store.state.timer); //关闭
            sessionStorage.clear();
            window.location.href = '/#/login?code=103001001'
        }, 1500)
    } else {
        return Promise.resolve(response['data']);
    }

}, (error) => {
    console.log(1111)
    //clearInterval(store.state.timer); //关闭
    //sessionStorage.clear();
    //window.location.href = '/#/login?code=103001001'
    return Promise.reject(error);
});

// 封装 POST 请求的参数
_axios.postData = (url, key, value) => { // TODO key 必须是一个字符串
    let params = new Object()
    params[key] = value
    return new Promise((resolve, reject) => {
        _axios.post(url, null, {
            params: params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    });
}

Vue.use(VueAxios, _axios);
