import Vue from 'vue'
// import 'lib-flexible'
import './rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入自定义css
import './assets/css/main.scss'

// 引入字体
import "./assets/font/font.css";

import moment from 'moment'

import './utils/VueEvent'
import './utils/globalFn'

// 引入echarts
import echarts from 'echarts'
// 引入 Vue粒子特效（vue-particles插件）
//import VueParticles from "vue-particles";

//  引入无缝滚动组件
import scroll from 'vue-seamless-scroll';


//  引入打印文件
import print from "./utils/print.js";
//Vue.use(Print) // 注册

// 引入 服务/路由/vuex
import './api'
import router from './router'
import store from "./store/index";
import App from './App.vue'

Vue.use(ElementUI);
//Vue.use(VueParticles);
Vue.use(scroll);
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment;//赋值使用
Vue.prototype.$print = print;//赋值使用

Vue.prototype.$titles = '云影像大数据平台'
Vue.config.productionTip = false



new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
