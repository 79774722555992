import Vue from 'vue'

Vue.prototype.$getCode = (object) => {
    let arr = []
    if (Array.isArray(object)) {
        arr = object.map(res => {
            return res.code || res
        })
    } else {
        arr.push(object.code)
    }

    return arr.join(',');
}

Vue.prototype.$getSizeString = (size)=> {
    if (!size)
        size = 0;
    if (0 <= size && size < 1024)
        return size.toFixed(2) + "M";
    else if (1024 <= size && size < 1024 * 1024)
        return (size / 1024).toFixed(2) + "G";
    else if (1024 * 1024 < size)
        return (size / (1024 * 1024)).toFixed(2) + "T";
}